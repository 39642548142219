import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { NUMBER_FORMATTER, toReadableText } from "../utils/utils";
import { green, red } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import { toggleRule, updateRuleGroupOrdering } from "../../api/ruleengine";
import IconButton from "@mui/material/IconButton";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

/**
 * Human readable ag grid rendered
 * Transforms the contents of a cell into human readable format
 */
export const HumanReadableFormatRenderer = (props) => {
  return toReadableText(props.value);
};

/**
 * Date Time ag grid rendered
 * Transforms the contents of a cell into a date object so it can be filtered appropriately
 */
export const LocaleDateTimeRenderer = (props) => {
  return new Date(props.value).toLocaleString();
};

/**
 * Switch ag grid rendered
 * Transforms a cell into a MUI Switch grid.
 * Exclusively used in the Rules grid, as it triggers a rule active/inactive switch
 */
export const SwitchRenderer = (props) => {
  const [checked, setChecked] = useState<boolean>(props.value);

  return (
    <Switch
      checked={checked}
      onChange={(event) => {
        setChecked(event.target.checked);
        toggleRule(props.data.id, event.target.checked)
          .then(() => props.context.onSuccess())
          .catch((err) => console.log(err));
      }}
      color={"success"}
    />
  );
};

/**
 * Move ag grid rendered
 * Transforms the contents of a cell into up and down buttons
 * Exclusively used in the rules grid as it moves rules orders
 */
export const MoveRuleRenderer = (props) => {
  const rowIndex = props.rowIndex;
  const totalNumRows = props.api.getDisplayedRowCount() - 1;

  const existingRuleIds = [];
  props.api.forEachNode((node) => existingRuleIds.push(node.data.id));
  const currentItemIndex = existingRuleIds.indexOf(props.data.id);

  /**
   * Move an item to a given index
   * @param toIndex : number
   */
  const moveItem = (toIndex) => {
    const element = existingRuleIds.splice(currentItemIndex, 1)[0];
    existingRuleIds.splice(toIndex, 0, element);
    return existingRuleIds;
  };

  return (
    <div>
      <IconButton
        disabled={rowIndex === 0}
        size={"small"}
        onClick={() => {
          updateRuleGroupOrdering(
            props.context.ruleGroupId,
            moveItem(rowIndex - 1)
          )
            .then(() => props.context.onSuccess())
            .catch((err) => console.log(err));
        }}
      >
        <ArrowCircleUpIcon />
      </IconButton>
      <IconButton
        size={"small"}
        disabled={rowIndex === totalNumRows}
        onClick={() => {
          updateRuleGroupOrdering(
            props.context.ruleGroupId,
            moveItem(rowIndex + 1)
          )
            .then(() => props.context.onSuccess())
            .catch((err) => {
              console.log(err);
            });
        }}
      >
        <ArrowCircleDownIcon />
      </IconButton>
    </div>
  );
};

/**
 * Return the rules primary action if there is only one action
 */
export const RuleActionsText = (props) => {
  let rules = props?.data?.actions;
  if (rules) {
    if (rules.length > 1) {
      return "Multiple Actions";
    } else {
      return toReadableText(rules[0].action_type);
    }
  } else {
    return "No Actions";
  }
};

/**
 * Compare two strings and generate an array of char differences
 * @param firstValue : string
 * @param secondValue : string
 */
export const compareStrings = (firstValue, secondValue) => {
  const diffs = [];
  const firstValueComponents = (firstValue || "").split("");
  const secondValueComponents = (secondValue || "").split("");
  let rollingIndex = 0;
  firstValueComponents.forEach((element, currentIndex) => {
    if (element === secondValueComponents[rollingIndex]) {
      diffs.push(" " + element);
      rollingIndex++;
    } else {
      if (
        firstValueComponents.length > currentIndex &&
        secondValueComponents.length > rollingIndex &&
        firstValueComponents[currentIndex + 1] ===
          secondValueComponents[rollingIndex + 1]
      ) {
        if (element === "\xa0") {
          diffs.push("- →");
        } else {
          diffs.push("- " + element);
        }
        diffs.push("+ " + secondValueComponents[rollingIndex]);
        rollingIndex++;
      } else if (
        firstValueComponents.length > currentIndex &&
        secondValueComponents.length > rollingIndex &&
        firstValueComponents[currentIndex] ===
          secondValueComponents[rollingIndex + 1]
      ) {
        diffs.push("+ " + secondValueComponents[rollingIndex]);
        diffs.push(" " + element);
        rollingIndex += 2;
      } else {
        diffs.push("- " + element);
      }
    }
  });
  while (rollingIndex < secondValueComponents.length) {
    diffs.push("+ " + secondValueComponents[rollingIndex]);
    rollingIndex++;
  }

  return diffs;
};

/**
 * Value renderer for ag grid
 * Adds a tooltip around the contents of a cell. Exclusively used by the Suggested changes grid
 * The tooltip highlights differences between old value and new value
 */
export const ValueChangeRenderer = (props) => {
  const [tooltipContents, setTooltipContents] = useState<any>(null);
  let value = props.value !== null ? props.value : "(unknown)";
  const computeDifferences = () => {
    let diff = compareStrings(props.data.old_value, props.data.new_value);
    if (
      diff &&
      diff.length > 0 &&
      ["country", "city"].indexOf(props.data.field) === -1
    ) {
      let data = diff.map((change, index) => {
        if (change.charAt(0) === " ") {
          return (
            <span key={change.charCodeAt(0)}>
              {change.charAt(change.length - 1)}
            </span>
          );
        } else if (change.charAt(0) === "+") {
          return (
            <span
              key={change.charCodeAt(0)}
              style={{ backgroundColor: green[700] }}
            >
              {change.charAt(change.length - 1)}
            </span>
          );
        } else if (change.charAt(0) === "-") {
          return (
            <span
              key={change.charCodeAt(0)}
              style={{ backgroundColor: red[700] }}
            >
              {change.charAt(change.length - 1)}
            </span>
          );
        }
        return "";
      });
      return data;
    }
    return "";
  };

  return (
    <Tooltip
      title={
        <div style={{ whiteSpace: "pre-wrap", fontSize: "medium" }}>
          {tooltipContents}
        </div>
      }
      onOpen={() => setTooltipContents(computeDifferences())}
    >
      {props?.data?.change_status === "WAITING_HUMAN_CHOICE" &&
      props?.data.new_value === props.value ? (
        <span>{"SELECTION REQUIRED"}</span>
      ) : (
        <span>{value}</span>
      )}
    </Tooltip>
  );
};

/**
 * Probability Cell renderer for ag grid
 * Generates a 5 star rating based on the % probability value
 */
export const ProbabilityCellRenderer = (props) => {
  let description = props.data.description
    ? JSON.parse(props.data.description.toString())
    : null;
  let tooltipTitle = "";
  let tooltipDetails = "";

  let probability =
    props.value !== null
      ? Math.round(props.value * 100) / 100
      : props.customRenderer || null;
  if (description) {
    tooltipTitle = description.Description + "\n";
    if (description.Breakdown) {
      description.Breakdown.forEach((item) => {
        if (item?.Description) {
          tooltipDetails += item.Description;
          if (item.AdditionalData) {
            item.AdditionalData.forEach((additionalData) => {
              tooltipDetails += "\n   • " + additionalData;
            });
          }
          tooltipDetails += "\n \n";
        }
      });
    }
  }
  return (
    <Tooltip
      title={
        <div style={{ whiteSpace: "pre-wrap", fontSize: "medium" }}>
          {tooltipTitle}
          <br />
          {tooltipDetails}
        </div>
      }
    >
      <span>{probability}</span>
    </Tooltip>
  );
};

/**
 * Return the contents of the new_value if we're changing the field in question
 *
 */
const getValueOrSuggestedChange = (identifier, props) => {
  if (identifier === "first_name") {
    if (props?.data?.field === "first_name") {
      return props?.data?.new_value;
    }
    return props?.data?.first_name;
  }
  if (identifier === "last_name") {
    if (props?.data?.field === "last_name") {
      return props?.data?.new_value;
    }
    return props?.data?.last_name;
  }
};
/**
 * Render a google search link
 */
export const GoogleSearchRenderer = (props) => {
  return (
    <span>{`https://www.google.com/search?q=${getValueOrSuggestedChange(
      "first_name",
      props
    )} ${getValueOrSuggestedChange("last_name", props)} ${
      props?.data?.company
    }`}</span>
  );
};

/**
 * Render a LinkedIn search link
 */
export const LinkedInSearchRenderer = (props) => {
  return (
    <span>{`https://www.linkedin.com/search/results/all/?keywords=${getValueOrSuggestedChange(
      "first_name",
      props
    )} ${getValueOrSuggestedChange("last_name", props)} : ${
      props?.data?.company
    }`}</span>
  );
};

/**
 * Cell renderer for how much % current row counts w.r.t the entire dataset
 */
export const countPercentageCellRenderer = (props) => {
  let allCounts = 0;
  props.api.forEachNode((node) => (allCounts += node.data.count));

  return (
    <span>
      {NUMBER_FORMATTER.format((props?.data?.count / allCounts) * 100)}
    </span>
  );
};
