import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import React, { useEffect, useState } from "react";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { AgGridReact } from "ag-grid-react";
import { deleteRule, getRuleGroup, Rule, RuleType } from "../../api/ruleengine";
import { RuleBuilder } from "./RuleBuilder";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import { CirromGridOverlay } from "../suggested-changes/CustomOverlay";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HelpIcon from "@mui/icons-material/Help";
import {
  MoveRuleRenderer,
  SwitchRenderer,
  RuleActionsText,
} from "../suggested-changes/CellRenderers";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ChickenOutModal } from "../utils/ChickenOutModal";
import classes from "./RuleViewer.module.css";
import { useNavigate } from "react-router-dom";
import "flag-icons/css/flag-icons.min.css";
export const RuleActionArea = (
  props: Readonly<{
    onEditButtonClicked(): void;
    onAddButtonClicked(): void;
    onRemoveButtonClicked(): void;
    selectedRow: number;
    testButtonVisible: boolean;
    onTestButtonClicked(): void;
    onCopyButtonClicked(): void;
  }>
) => {
  const {
    onEditButtonClicked,
    onAddButtonClicked,
    onRemoveButtonClicked,
    selectedRow,
    onTestButtonClicked,
    testButtonVisible,
    onCopyButtonClicked,
  } = props;

  const [chickenOutModalOpen, setChickenOutModalOpen] =
    useState<boolean>(false);
  return (
    <>
      <ChickenOutModal
        message={
          "The rule will no longer be evaluated against any data sources."
        }
        title={"Are you sure you want to remove this rule?"}
        modalOpen={chickenOutModalOpen}
        onModalOpenChange={setChickenOutModalOpen}
        onSuccess={onRemoveButtonClicked}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        size="small"
        className={classes.ruleButtons}
        onClick={() => onAddButtonClicked()}
      >
        Add
      </Button>

      <Button
        variant="contained"
        color="primary"
        startIcon={<RemoveOutlinedIcon />}
        size="small"
        className={classes.ruleButtons}
        onClick={() => setChickenOutModalOpen(true)}
        disabled={!selectedRow && selectedRow !== 0}
      >
        Remove
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<EditOutlinedIcon />}
        size="small"
        className={classes.ruleButtons}
        onClick={() => onEditButtonClicked()}
        disabled={!selectedRow && selectedRow !== 0}
      >
        Edit
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ContentCopyIcon />}
        size="small"
        className={classes.ruleButtons}
        onClick={() => onCopyButtonClicked()}
        disabled={!selectedRow && selectedRow !== 0}
      >
        Copy
      </Button>
      {testButtonVisible && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<HelpIcon />}
          size="small"
          className={classes.ruleButtons}
          onClick={() => onTestButtonClicked()}
          disabled={!selectedRow && selectedRow !== 0}
        >
          Test
        </Button>
      )}
    </>
  );
};

const NewRuleModal = (props: {
  integratedSystemId: number;
  isOpen: boolean;
  selectedRuleId: number;
  onClose: () => void;
  onRuleSaved: (_ruleDetails) => void;
  ruleGroupId: number;
  ruleType: RuleType;
  selectedRule: Rule;
}) => {
  return (
    <Modal open={props.isOpen} className={classes.modal}>
      <Container maxWidth="lg">
        <Card style={{ padding: "16px" }}>
          <CardHeader
            title={!props.selectedRuleId ? "New Rule" : "Edit Rule"}
            titleTypographyProps={{ variant: "h3" }}
            action={
              <IconButton onClick={props.onClose}>
                <CloseOutlinedIcon />
              </IconButton>
            }
          />
          <Divider />
          <CardContent
            style={{
              maxHeight: (window.outerHeight * 0.7).toString() + "px",
              overflow: "overlay",
            }}
          >
            {props.selectedRuleId !== undefined && (
              <RuleBuilder
                integratedSystemId={props.integratedSystemId}
                ruleId={props.selectedRuleId}
                onRuleSaved={props.onRuleSaved}
                ruleGroupId={props.ruleGroupId}
                ruleType={props.ruleType}
              />
            )}
            {props.selectedRuleId === undefined && props.selectedRule && (
              <RuleBuilder
                integratedSystemId={props.integratedSystemId}
                rule={props.selectedRule}
                onRuleSaved={props.onRuleSaved}
                ruleGroupId={props.ruleGroupId}
                ruleType={props.ruleType}
              />
            )}
          </CardContent>
        </Card>
      </Container>
    </Modal>
  );
};

export const RuleViewer = (
  props: Readonly<{
    integratedSystemId: number;
    ruleGroupId: number;
    ruleType: RuleType;
    selectedBatchGroupId?: number;
    headerTitle?: string;
  }>
) => {
  const { ruleGroupId, ruleType, selectedBatchGroupId } = props;

  const [existingRules, setExistingRules] = useState<Rule[]>([]);
  const [selectedRuleId, setSelectedRuleId] = useState<number>(undefined);
  const [selectedRule, setSelectedRule] = useState<Rule>(undefined);
  const [ruleGroupName, setRuleGroupName] = useState<string>(undefined);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState(undefined);

  const navigate = useNavigate();
  useEffect(() => {
    reloadRuleGroup();
    setSelectedRuleId(undefined);
    setSelectedRule(undefined);
  }, [ruleGroupId]);

  const reloadRuleGroup = () => {
    getRuleGroup(ruleGroupId)
      .then((response) => {
        setRuleGroupName(response.name);
        setExistingRules(response.rules);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Grid container spacing={1}>
      <Grid item lg={12} sm={12} xl={12} xs={12}>
        <Card style={{ height: "100%" }}>
          <CardHeader
            title={props.headerTitle || `Existing Rules - ${ruleGroupName}`}
          />
          <Divider />
          <CardContent>
            <RuleActionArea
              onEditButtonClicked={() => setShowEditModal(true)}
              onAddButtonClicked={() => {
                setSelectedRuleId(null);
                setSelectedRule(null);
                if (gridApi) {
                  gridApi.deselectAll();
                }

                setShowEditModal(true);
              }}
              onRemoveButtonClicked={() => {
                if (selectedRuleId) {
                  deleteRule(selectedRuleId)
                    .then((_response) => reloadRuleGroup())
                    .catch((err) => console.log(err));
                }
              }}
              onTestButtonClicked={() => {
                if (selectedBatchGroupId && selectedRuleId) {
                  navigate(
                    `/app/configure/rule-evaluation/${selectedBatchGroupId}/${selectedRuleId}`
                  );
                }
              }}
              testButtonVisible={selectedBatchGroupId !== undefined}
              selectedRow={selectedRuleId}
              onCopyButtonClicked={() => {
                const ruleToCopy = existingRules.filter(
                  (rule) => rule.id === selectedRuleId
                );
                if (ruleToCopy.length === 1) {
                  setSelectedRule({ ...ruleToCopy[0], id: undefined });
                  setSelectedRuleId(undefined);
                  setShowEditModal(true);
                }
              }}
            />
            <div style={{ height: "550px" }} className="ag-theme-material">
              <AgGridReact
                columnDefs={[
                  {
                    field: "not_id",
                    headerName: "",
                    checkboxSelection: true,
                    width: 25,
                  },
                  {
                    field: "move_rule_col",
                    headerName: "Rule Order",
                    width: 125,
                    cellRenderer: "moveRuleRenderer",
                  },
                  {
                    field: "active",
                    headerName: "Active",
                    sortable: true,
                    resizable: true,
                    width: 125,
                    cellRenderer: "switchRenderer",
                  },
                  {
                    field: "description",
                    headerName: "Rule Description",
                    sortable: true,
                    resizable: true,
                    flex: 0.7,
                  },
                  {
                    field: "actions",
                    headerName: "Actions",
                    sortable: true,
                    resizable: true,
                    flex: 0.3,
                    cellRenderer: "RuleActionsText",
                  },
                  {
                    field: "id",
                    headerName: "id",
                    hide: true,
                  },
                ]}
                rowData={existingRules}
                onGridReady={(params) => setGridApi(params.api)}
                loadingOverlayComponent="cirromGridOverlay"
                components={{
                  cirromGridOverlay: CirromGridOverlay,
                  switchRenderer: SwitchRenderer,
                  moveRuleRenderer: MoveRuleRenderer,
                  RuleActionsText: RuleActionsText,
                }}
                onRowDoubleClicked={(rowEv) => {
                  setSelectedRuleId(rowEv.node.data.id);
                  setShowEditModal(true);
                }}
                onSelectionChanged={(params) => {
                  if (gridApi.getSelectedNodes().length > 0) {
                    setSelectedRule(undefined);
                    setSelectedRuleId(gridApi.getSelectedNodes()[0].data.id);
                  } else {
                    setSelectedRuleId(null);
                    setSelectedRule(null);
                  }
                }}
                context={{
                  ruleGroupId: props.ruleGroupId,
                  onSuccess: reloadRuleGroup,
                  batchGroupId: selectedBatchGroupId,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Grid>

      <NewRuleModal
        integratedSystemId={props.integratedSystemId}
        isOpen={showEditModal}
        selectedRuleId={selectedRuleId}
        onClose={() => setShowEditModal(false)}
        onRuleSaved={(_ruleDetails) => {
          reloadRuleGroup();
          setShowEditModal(false);
        }}
        ruleGroupId={ruleGroupId}
        ruleType={ruleType}
        selectedRule={selectedRule}
      />
    </Grid>
  );
};
