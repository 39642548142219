import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React from "react";
import { Helmet } from "react-helmet";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import classes from "./PageTitle.module.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import { IntegratedSystem } from "../../api/integratedsystems";
import { DataProject } from "../../api/dataprojects";
/**
 * PageTitle Component
 *
 * A component to display the page title, header, and optional subheader, along with an optional
 * breadcrumb-like navigation for a system or project. It also sets the page title for the document.
 *
 * @param {string} props.pageTitle - The title of the page to set as the document title.
 * @param {string} props.pageHeader - The main header of the page.
 * @param {string} [props.systemOrProjectHeader] - The header for the system or project, used as part of the breadcrumb.
 * @param {string} [props.pageSubHeader] - An optional subheader for the page.
 * @param {any} [props.children] - Optional children elements to be rendered within the component.
 * @param {boolean} [props.isDataProject] - A flag indicating whether the page represents a data project.
 * @param {number} [props.integratedSystemId] - The ID of the integrated system associated with the page (used in breadcrumb navigation).
 * @param {(IntegratedSystem[] | DataProject[])} [props.userSystems] - An array of integrated systems or data projects for breadcrumb navigation.
 *
 * @returns {JSX.Element} - The rendered PageTitle component.
 */
export function PageTitle(
  props: Readonly<{
    pageTitle: string;
    pageHeader: any;
    systemOrProjectHeader?: string;
    pageSubHeader?: string;
    children?: any;
    isDataProject?: boolean;
    integratedSystemId?: number;
    userSystems?: IntegratedSystem[] | DataProject[];
  }>
) {
  // Destructure props
  const {
    children,
    pageTitle,
    pageHeader,
    systemOrProjectHeader,
    pageSubHeader,
    isDataProject,
    integratedSystemId,
  } = props;

  return (
    <>
      {/* Set the page title for the document */}
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        style={{
          backgroundColor: "background.default",
        }}
      >
        <Container maxWidth={false}>
          {/* Breadcrumb-like navigation when a system or project header is provided */}
          {systemOrProjectHeader && (
            <Typography
              color="textPrimary"
              variant="h3"
              className={classes.breadcrumb}
            >
              {/* Icon and link to the system or project */}
              {!isDataProject && (
                <AssignmentOutlinedIcon
                  fontSize={"small"}
                  style={{ marginRight: "2px" }}
                />
              )}
              {isDataProject && (
                <BallotOutlinedIcon
                  fontSize={"small"}
                  style={{ marginRight: "2px" }}
                />
              )}
              <Link
                to={{
                  pathname: isDataProject
                    ? `/app/data-project/${integratedSystemId}`
                    : `/app/dashboard/${integratedSystemId}`,
                }}
                className={classes.breadcrumbItem}
              >
                {systemOrProjectHeader}
              </Link>
              <NavigateNextIcon fontSize={"small"} />
              {/* Page header */}
              {pageHeader}
            </Typography>
          )}
          {/* Display only the page header if there's no system or project header */}
          {pageHeader && systemOrProjectHeader === undefined && (
            <Typography
              color="textPrimary"
              variant="h3"
              style={{ margin: "1em 0", marginBottom: 0 }}
            >
              {pageHeader}
            </Typography>
          )}
          {/* Optional subheader */}
          {pageSubHeader && (
            <Typography variant="subtitle1">{pageSubHeader}</Typography>
          )}

          {/* Divider between title and content */}
          <Divider style={{ margin: "5px 0" }} />
        </Container>
        {/* Render any additional children elements passed to the component */}
        {children}
      </Box>
    </>
  );
}
