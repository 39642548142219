import { ActiveDirectory } from "../pages/ActiveDirectory";
import { AppLayout } from "../pages/AppLayout";
import { BaseLayout } from "../pages/BaseLayout";
import { Configuration } from "../pages/Configuration";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Navigate } from "react-router-dom";
import { NotFound } from "../pages/NotFound";
import { UploadedFiles } from "../pages/UploadedFiles";
import { Users } from "../pages/Users";
import { UploadSettings } from "../pages/UploadSettings";
import { Sidebar } from "./sidebar/Sidebar";
import { ConfigSidebar } from "./sidebar/ConfigSidebar";
import React from "react";
import { ChangeTicketsPage } from "../pages/ChangeTickets";
import { ChangedFieldsPage } from "../pages/ChangedFields";
import { IntegratedSystemsRules } from "../pages/IntegratedSystemsRules";
import { ComplianceRules } from "../pages/ComplianceRules";
import { DuplicatesPage } from "../pages/Duplicates";
import { RuleEvaluation } from "../pages/RuleEvaluation";
import { Disabled } from "../pages/Disabled";
import { Deployments } from "../pages/Deployments";
import { Accounts } from "../pages/Accounts";
import { DataProjectPage } from "../pages/DataProject";
import { NameChangesPage } from "../pages/NameChanges";
import { EmailChangesPage } from "../pages/EmailChanges";
import { LocationChangesPage } from "../pages/LocationChanges";
import { EmploymentChangesPage } from "../pages/EmploymentChanges";
import { DataProjects } from "../pages/DataProjects";
import { SecretsAndFlagsPage } from "../pages/Secrets";
import { SupportPage } from "../pages/Support";
import { RejectedFieldsPage } from "../pages/RejectedFields";
import { Neo } from "../pages/Neo";
import { MonthlyReport } from "../pages/MonthlyReport";
import { Addresses } from "../pages/Addresses";
import { CirromSyncPage } from "../pages/Sync";

const getAccessPathForLoggedInUser = (originalPath: string) => {
  if (window.location.pathname === "/404") {
    return;
  }
  if (["/active-directory", "/login", "/"].indexOf(originalPath) > -1) {
    return "/app/dashboard";
  }
  return originalPath;
};

/**
 * Route set up
 * @param isLoggedIn
 * @param setLoggedIn
 * @param onLogout
 * @param originalAccessPath
 * @param userProfile
 * @param userSystems
 * @param dataProjects
 * @param fetchDataProjects
 * @param selectedSystemId
 * @param setSelectedSystemId
 * @param setUserProfile
 * @param currentUser
 * @constructor
 */
export const Routes = (
  isLoggedIn,
  setLoggedIn,
  onLogout,
  originalAccessPath,
  userProfile,
  userSystems,
  dataProjects,
  fetchDataProjects,
  selectedSystemId,
  setSelectedSystemId,
  setUserProfile,
  currentUser
) => [
  {
    path: "/app/configure",
    element: isLoggedIn ? (
      <AppLayout
        onLogout={onLogout}
        Sidebar={ConfigSidebar}
        userProfile={userProfile}
        selectedSystemId={selectedSystemId}
        setSelectedSystemId={setSelectedSystemId}
        userSystems={userSystems}
        currentUser={currentUser}
      />
    ) : (
      <Navigate to="/login" state={{ hasRedirect: true }} />
    ),
    children: [
      { path: "/app/configure", element: <Configuration /> },
      { path: "/app/configure/batch", element: <Configuration /> },
      { path: "/app/configure/upload-settings", element: <UploadSettings /> },
      {
        path: "/app/configure/system-rules/",
        element: <IntegratedSystemsRules />,
      },
      {
        path: "/app/configure/compliance-rules/",
        element: <ComplianceRules />,
      },
      {
        path: "/app/configure/users",
        element: <Users userSystems={userSystems} />,
      },
      { path: "/app/configure/deployments", element: <Deployments /> },
      {
        path: "/app/configure/rule-evaluation/:batchGroupId/:ruleId",
        element: <RuleEvaluation />,
      },
      { path: "/app/configure/secrets", element: <SecretsAndFlagsPage /> },
      { path: "/app/configure/support", element: <SupportPage /> },

      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/app",
    element: isLoggedIn ? (
      <AppLayout
        onLogout={onLogout}
        Sidebar={Sidebar}
        userProfile={userProfile}
        selectedSystemId={selectedSystemId}
        setSelectedSystemId={setSelectedSystemId}
        userSystems={userSystems}
        currentUser={currentUser}
      />
    ) : (
      <Navigate to="/login" state={{ hasRedirect: true }} />
    ),
    children: [
      {
        path: "/app/dashboard/old",
        element: (
          <Home
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/dashboard",
        element: (
          <Home
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/dashboard/:integratedSystemId",
        element: (
          <Home
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/data-projects/:integratedSystemId",
        element: (
          <DataProjects
            dataProjects={dataProjects}
            fetchDataProjects={fetchDataProjects}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/data-project/:dataProjectId",
        element: (
          <DataProjectPage
            dataProjects={dataProjects}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      { path: "/app/my-data", element: <UploadedFiles /> },
      { path: "/app/my-data/:id", element: <UploadedFiles /> },

      {
        path: "/app/change-tickets/:integratedSystemId",
        element: (
          <ChangeTicketsPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },

      {
        path: "/app/crm/name-changes/:integratedSystemId",
        element: (
          <NameChangesPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/data-project/name-changes/:integratedSystemId",
        element: (
          <NameChangesPage
            userSystems={dataProjects}
            isDataProject={true}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/crm/email-changes/:integratedSystemId",
        element: (
          <EmailChangesPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/data-project/email-changes/:integratedSystemId",
        element: (
          <EmailChangesPage
            userSystems={dataProjects}
            isDataProject
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },

      {
        path: "/app/crm/location-changes/:integratedSystemId",
        element: (
          <LocationChangesPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/data-project/location-changes/:integratedSystemId",
        element: (
          <LocationChangesPage
            userSystems={dataProjects}
            isDataProject
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/crm/employment-changes/:integratedSystemId",
        element: (
          <EmploymentChangesPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/data-project/employment-changes/:integratedSystemId",
        element: (
          <EmploymentChangesPage
            userSystems={dataProjects}
            isDataProject
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },

      {
        path: "/app/changed-fields/:integratedSystemId",
        element: (
          <ChangedFieldsPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/rejected-fields/:integratedSystemId",
        element: (
          <RejectedFieldsPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      process.env.REACT_APP_NEO_ENABLED === "1"
        ? {
            path: "/app/neo/:integratedSystemId",
            element: (
              <Neo
                userSystems={userSystems}
                setSelectedSystemId={setSelectedSystemId}
                isDataProject={false}
                currentUser={currentUser}
              />
            ),
          }
        : {
            path: "/app/rejected-fields/:integratedSystemId",
            element: (
              <RejectedFieldsPage
                userSystems={userSystems}
                setSelectedSystemId={setSelectedSystemId}
              />
            ),
          },
      {
        path: "/app/change-tickets/:integratedSystemId/:selectedTab",
        element: (
          <ChangeTicketsPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/changed-fields/:integratedSystemId/:selectedTab",
        element: (
          <ChangedFieldsPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/duplicates/:integratedSystemId/*",
        element: (
          <DuplicatesPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/data-project/duplicates/:integratedSystemId/*",
        element: (
          <DuplicatesPage
            userSystems={dataProjects}
            isDataProject
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
        exact: true,
      },

      {
        path: "/app/accounts/:integratedSystemId",
        element: (
          <Accounts
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/addresses/:integratedSystemId",
        element: (
          <Addresses
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      {
        path: "/app/sync/:integratedSystemId",
        element: (
          <CirromSyncPage
            userSystems={userSystems}
            setSelectedSystemId={setSelectedSystemId}
          />
        ),
      },
      { path: "/app/report", element: <MonthlyReport /> },

      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn ? (
      <BaseLayout />
    ) : (
      getAccessPathForLoggedInUser(originalAccessPath) && (
        <Navigate to={getAccessPathForLoggedInUser(originalAccessPath)} />
      )
    ),
    children: [
      {
        path: "/disabled",
        element: <Disabled />,
      },
      {
        path: "/login",
        element: (
          <Login setLoggedIn={setLoggedIn} setUserProfile={setUserProfile} />
        ),
      },
      {
        path: "/",
        element: <Navigate to="/login" state={{ hasRedirect: true }} />,
      },
      {
        path: "/active-directory",
        element: <ActiveDirectory setLoggedIn={setLoggedIn} />,
      },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];
