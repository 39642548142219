import React, { useContext } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Link, NavLink, useLocation } from "react-router-dom";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import { IntegratedSystem } from "../../api/integratedsystems";
import Typography from "@mui/material/Typography";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import classes from "./Sidebar.module.css";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import SyncIcon from "@mui/icons-material/Sync";
import Button from "@mui/material/Button";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Icon from "@mui/material/Icon";
import { SideBarWidthContext } from "../../App";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
export interface SidebarItemProps {}

export function SidebarItem(
  props: Readonly<{ isGroup: boolean; userSystem?: IntegratedSystem }>
) {
  const { isGroup, userSystem } = props;
  const currentLocation = useLocation();

  const [open, setOpen] = React.useState(true);

  const sideBarWidth = useContext(SideBarWidthContext);

  const isMinimized = sideBarWidth.value === "small";

  return (
    <>
      {isGroup ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.subItem}>
              <Link
                to={{ pathname: `/app/crm/name-changes/${userSystem.id}` }}
                className={isMinimized ? classes.itemMin : classes.item}
                data-testid={"name_btn"}
                style={{
                  ...(currentLocation.pathname.toLowerCase() ===
                    `/app/crm/name-changes/${userSystem.id}` && {
                    backgroundColor: "#333333",
                    color: "white",
                  }),
                }}
              >
                <BadgeOutlinedIcon fontSize={"small"} />
                {!isMinimized && <Typography variant={"h6"}>Name</Typography>}
                {isMinimized && (
                  <Typography variant={"caption"}>Name</Typography>
                )}
              </Link>
            </ListItem>
            <ListItem className={classes.subItem}>
              <Link
                className={isMinimized ? classes.itemMin : classes.item}
                style={{
                  ...(currentLocation.pathname.toLowerCase() ===
                    `/app/crm/email-changes/${userSystem.id}` && {
                    backgroundColor: "#333333",
                    color: "white",
                  }),
                }}
                to={{ pathname: `/app/crm/email-changes/${userSystem.id}` }}
              >
                <AlternateEmailIcon fontSize={"small"} />
                {!isMinimized && <Typography variant={"h6"}>Email</Typography>}
                {isMinimized && (
                  <Typography variant={"caption"}>Email</Typography>
                )}
              </Link>
            </ListItem>
            <ListItem className={classes.subItem}>
              <Link
                className={isMinimized ? classes.itemMin : classes.item}
                style={{
                  ...(currentLocation.pathname.toLowerCase() ===
                    `/app/crm/location-changes/${userSystem.id}` && {
                    backgroundColor: "#333333",
                    color: "white",
                  }),
                }}
                to={{ pathname: `/app/crm/location-changes/${userSystem.id}` }}
              >
                <PersonPinCircleOutlinedIcon fontSize={"small"} />
                {!isMinimized && (
                  <Typography variant={"h6"}>Location</Typography>
                )}
                {isMinimized && (
                  <Typography variant={"caption"}>Loc.</Typography>
                )}
              </Link>
            </ListItem>
            <ListItem className={classes.subItem}>
              <Link
                data-testid={"employment_btn"}
                className={isMinimized ? classes.itemMin : classes.item}
                style={{
                  ...(currentLocation.pathname.toLowerCase() ===
                    `/app/crm/employment-changes/${userSystem.id}` && {
                    backgroundColor: "#333333",
                    color: "white",
                  }),
                }}
                to={{
                  pathname: `/app/crm/employment-changes/${userSystem.id}`,
                }}
              >
                <WorkOutlineOutlinedIcon fontSize={"small"} />
                {!isMinimized && (
                  <Typography variant={"h6"}>Employment</Typography>
                )}
                {isMinimized && (
                  <Typography variant={"caption"}>Employ.</Typography>
                )}
              </Link>
            </ListItem>
            <ListItem className={classes.subItem}>
              <Link
                data-testid={"dupes_btn"}
                className={isMinimized ? classes.itemMin : classes.item}
                style={{
                  ...(currentLocation.pathname.toLowerCase() ===
                    `/app/duplicates/${userSystem.id}` && {
                    backgroundColor: "#333333",
                    color: "white",
                  }),
                }}
                to={{ pathname: `/app/duplicates/${userSystem.id}` }}
              >
                <PeopleAltOutlinedIcon fontSize={"small"} />
                {!isMinimized && (
                  <Typography variant={"h6"}>Duplicates</Typography>
                )}
                {isMinimized && (
                  <Typography variant={"caption"}>Dupes.</Typography>
                )}
              </Link>
            </ListItem>
            <ListItem className={classes.subItem}>
              <Link
                to={{ pathname: `/app/changed-fields/${userSystem.id}` }}
                className={isMinimized ? classes.itemMin : classes.item}
                style={{
                  ...(currentLocation.pathname.toLowerCase() ===
                    `/app/changed-fields/${userSystem.id}` && {
                    backgroundColor: "#333333",
                    color: "white",
                  }),
                }}
              >
                <AssignmentTurnedInOutlinedIcon fontSize={"small"} />
                {!isMinimized && (
                  <Typography variant={"h6"}>Changed Fields</Typography>
                )}
                {isMinimized && (
                  <Typography variant={"caption"}>Applied</Typography>
                )}
              </Link>
            </ListItem>
            <ListItem className={classes.subItem}>
              <Link
                to={{ pathname: `/app/rejected-fields/${userSystem.id}` }}
                className={isMinimized ? classes.itemMin : classes.item}
                style={{
                  ...(currentLocation.pathname.toLowerCase() ===
                    `/app/rejected-fields/${userSystem.id}` && {
                    backgroundColor: "#333333",
                    color: "white",
                  }),
                }}
              >
                <CancelPresentationIcon fontSize={"small"} />
                {!isMinimized && (
                  <Typography variant={"h6"}>Rejected Fields</Typography>
                )}
                {isMinimized && (
                  <Typography variant={"caption"}>Rejected</Typography>
                )}
              </Link>
            </ListItem>
            <ListItem className={classes.subItem}>
              <Link
                to={{ pathname: `/app/accounts/${userSystem.id}` }}
                className={isMinimized ? classes.itemMin : classes.item}
                style={{
                  ...(currentLocation.pathname.toLowerCase() ===
                    `/app/accounts/${userSystem.id}` && {
                    backgroundColor: "#333333",
                    color: "white",
                  }),
                }}
              >
                <AccountTreeOutlinedIcon fontSize={"small"} />
                {!isMinimized && (
                  <Typography variant={"h6"}>Accounts</Typography>
                )}
                {isMinimized && (
                  <Typography variant={"caption"}>Accts.</Typography>
                )}
              </Link>
            </ListItem>
            {process.env.REACT_APP_ADDRESS_MERGE_ENABLED === "1" && (
              <ListItem className={classes.subItem}>
                <Link
                  to={{ pathname: `/app/addresses/${userSystem.id}` }}
                  className={isMinimized ? classes.itemMin : classes.item}
                  style={{
                    ...(currentLocation.pathname.toLowerCase() ===
                      `/app/addresses/${userSystem.id}` && {
                      backgroundColor: "#333333",
                      color: "white",
                    }),
                  }}
                >
                  <MarkunreadMailboxOutlinedIcon fontSize={"small"} />

                  {!isMinimized && (
                    <Typography variant={"h6"}>Addresses</Typography>
                  )}
                  {isMinimized && (
                    <Typography variant={"caption"}>Adds.</Typography>
                  )}
                </Link>
              </ListItem>
            )}
            {process.env.REACT_APP_SYNC_ENABLED === "1" && (
              <ListItem className={classes.subItem}>
                <Link
                  to={{ pathname: `/app/sync/${userSystem.id}` }}
                  className={isMinimized ? classes.itemMin : classes.item}
                  style={{
                    ...(currentLocation.pathname.toLowerCase() ===
                      `/app/sync/${userSystem.id}` && {
                      backgroundColor: "#333333",
                      color: "white",
                    }),
                  }}
                >
                  <SyncIcon fontSize={"small"} />

                  {!isMinimized && <Typography variant={"h6"}>Sync</Typography>}
                  {isMinimized && (
                    <Typography variant={"caption"}>Sync</Typography>
                  )}
                </Link>
              </ListItem>
            )}
            {process.env.REACT_APP_NEO_ENABLED === "1" && (
              <ListItem className={classes.subItem}>
                <Link
                  to={{ pathname: `/app/neo/${userSystem.id}` }}
                  className={isMinimized ? classes.itemMin : classes.item}
                  style={{
                    ...(currentLocation.pathname.toLowerCase() ===
                      `/app/neo/${userSystem.id}` && {
                      backgroundColor: "#333333",
                      color: "white",
                    }),
                  }}
                >
                  <Icon>
                    <img
                      src={"/neo_head.svg"}
                      alt={"cirrom neo logo"}
                      className={classes.neo}
                    />
                  </Icon>

                  {!isMinimized && (
                    <Typography variant={"h6"} style={{ marginLeft: "-4px" }}>
                      Neo
                    </Typography>
                  )}
                  {isMinimized && (
                    <Typography variant={"caption"}>Neo</Typography>
                  )}
                </Link>
              </ListItem>
            )}
          </List>
        </Collapse>
      ) : null}
    </>
  );
}

export function ConfigSidebarItem(
  props: Readonly<{
    location: string;
    Icon: typeof SettingsIcon;
    title: string;
    isGroup: boolean;
  }>
) {
  const { title, isGroup, Icon } = props;

  const [open, setOpen] = React.useState(true);

  return (
    <ListItem
      disableGutters
      style={{
        display: "flex",
        padding: 0,
      }}
    >
      <Button component={NavLink} to={props.location} className={classes.item}>
        <Icon style={{ marginRight: "0.25rem" }} />
        <span style={{ wordBreak: "break-word" }}>{title}</span>
      </Button>
      {isGroup && open && (
        <ExpandLess style={{ color: "black" }} onClick={() => setOpen(false)} />
      )}
      {isGroup && !open && (
        <ExpandMore style={{ color: "black" }} onClick={() => setOpen(true)} />
      )}
    </ListItem>
  );
}
